import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const ThankYouPage = () => {
  return (
    <div>
      <Helmet>
        <html lang="de" />
        <title>
          TGS lime tree: Your full-service Tax Partner in the Netherlands
        </title>
        <meta
          name="description"
          content="From the heart of Amsterdam, we provide services to small- and medium-sized companies that wish to set foot or expand in Europe or the Netherlands."
        />
        <meta name="robots" content="noindex" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      </Helmet>
      <div className="flex items-center justify-center h-screen">
        <div>
          <h1 className="mb-4 text-4xl">Thank you for your submission!</h1>
          <p className="mb-16">One of our experts will contact you shortly.</p>
          <Link
            to="/"
            className="px-4 py-2 mt-32 text-orange-500 rounded-md shadow"
          >
            Go back
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ThankYouPage
